export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 52,
  w: 377,
  h: 377,
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 1,
              s: [0]
            },
            { t: 39, s: [180] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.767 },
              o: { x: 0.92, y: 0 },
              t: 1,
              s: [166.5, 166.5, 0],
              to: [1.333, -8.5, 0],
              ti: [28.489, 5.112, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.103 },
              t: 27,
              s: [132.5, 90.5, 0],
              to: [-57.659, -10.345, 0],
              ti: [0, 0, 0]
            },
            { t: 39, s: [52.5, 123.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 34,
              s: [100, 100, 100]
            },
            { t: 39, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.089, -0.019],
                    [0, 0],
                    [0.309, -0.309],
                    [0.084, -0.425],
                    [0, 0],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0.017, 0.088],
                    [0, 0],
                    [0.309, 0.309],
                    [0.43, 0.09],
                    [0, 0],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07],
                    [-0.089, 0.019],
                    [0, 0],
                    [-0.309, 0.309],
                    [-0.084, 0.425],
                    [0, 0],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [-0.017, -0.088],
                    [0, 0],
                    [-0.309, -0.309],
                    [-0.43, -0.09],
                    [0, 0],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07]
                  ],
                  o: [
                    [0, 0],
                    [-0.43, 0.09],
                    [-0.309, 0.309],
                    [0, 0],
                    [-0.017, 0.088],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [0, 0],
                    [-0.084, -0.425],
                    [-0.309, -0.309],
                    [0, 0],
                    [-0.089, -0.019],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07],
                    [0, 0],
                    [0.43, -0.09],
                    [0.309, -0.309],
                    [0, 0],
                    [0.017, -0.088],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0, 0],
                    [0.084, 0.425],
                    [0.309, 0.309],
                    [0, 0],
                    [0.089, 0.019],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07]
                  ],
                  v: [
                    [3.186, 0.384],
                    [2.299, 0.564],
                    [1.166, 1.175],
                    [0.564, 2.299],
                    [0.384, 3.186],
                    [0.25, 3.411],
                    [0, 3.5],
                    [-0.25, 3.411],
                    [-0.384, 3.186],
                    [-0.564, 2.299],
                    [-1.166, 1.174],
                    [-2.299, 0.564],
                    [-3.186, 0.384],
                    [-3.412, 0.246],
                    [-3.5, 0],
                    [-3.412, -0.246],
                    [-3.186, -0.384],
                    [-2.299, -0.564],
                    [-1.166, -1.174],
                    [-0.564, -2.299],
                    [-0.384, -3.186],
                    [-0.25, -3.411],
                    [0, -3.5],
                    [0.25, -3.411],
                    [0.384, -3.186],
                    [0.564, -2.299],
                    [1.166, -1.174],
                    [2.299, -0.564],
                    [3.186, -0.384],
                    [3.412, -0.246],
                    [3.5, 0],
                    [3.412, 0.246]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 8,
      op: 1001,
      st: 1,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 1,
              s: [0]
            },
            { t: 25, s: [180] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.813 },
              o: { x: 0.92, y: 0 },
              t: 1,
              s: [222.5, 167.5, 0],
              to: [-3.333, -16.5, 0],
              ti: [-37.73, 2.19, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.142 },
              t: 25,
              s: [270.5, 98.5, 0],
              to: [42.067, -2.442, 0],
              ti: [0, 0, 0]
            },
            { t: 40, s: [325.5, 142.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 33,
              s: [100, 100, 100]
            },
            { t: 38, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.064, -0.014],
                    [0, 0],
                    [0.22, -0.22],
                    [0.06, -0.303],
                    [0, 0],
                    [0.05, -0.041],
                    [0.065, 0],
                    [0.05, 0.041],
                    [0.012, 0.063],
                    [0, 0],
                    [0.22, 0.22],
                    [0.307, 0.064],
                    [0, 0],
                    [0.041, 0.05],
                    [0, 0.064],
                    [-0.041, 0.05],
                    [-0.064, 0.014],
                    [0, 0],
                    [-0.22, 0.22],
                    [-0.06, 0.303],
                    [0, 0],
                    [-0.05, 0.041],
                    [-0.065, 0],
                    [-0.05, -0.041],
                    [-0.012, -0.063],
                    [0, 0],
                    [-0.22, -0.22],
                    [-0.307, -0.064],
                    [0, 0],
                    [-0.041, -0.05],
                    [0, -0.064],
                    [0.041, -0.05]
                  ],
                  o: [
                    [0, 0],
                    [-0.307, 0.064],
                    [-0.22, 0.22],
                    [0, 0],
                    [-0.012, 0.063],
                    [-0.05, 0.041],
                    [-0.065, 0],
                    [-0.05, -0.041],
                    [0, 0],
                    [-0.06, -0.303],
                    [-0.22, -0.22],
                    [0, 0],
                    [-0.064, -0.014],
                    [-0.041, -0.05],
                    [0, -0.064],
                    [0.041, -0.05],
                    [0, 0],
                    [0.307, -0.064],
                    [0.22, -0.22],
                    [0, 0],
                    [0.012, -0.063],
                    [0.05, -0.041],
                    [0.065, 0],
                    [0.05, 0.041],
                    [0, 0],
                    [0.06, 0.303],
                    [0.22, 0.22],
                    [0, 0],
                    [0.064, 0.014],
                    [0.041, 0.05],
                    [0, 0.064],
                    [-0.041, 0.05]
                  ],
                  v: [
                    [2.276, 0.274],
                    [1.642, 0.403],
                    [0.833, 0.839],
                    [0.403, 1.642],
                    [0.274, 2.276],
                    [0.178, 2.437],
                    [0, 2.5],
                    [-0.178, 2.437],
                    [-0.274, 2.276],
                    [-0.403, 1.642],
                    [-0.833, 0.839],
                    [-1.642, 0.403],
                    [-2.276, 0.274],
                    [-2.437, 0.176],
                    [-2.5, 0],
                    [-2.437, -0.176],
                    [-2.276, -0.274],
                    [-1.642, -0.403],
                    [-0.833, -0.839],
                    [-0.403, -1.642],
                    [-0.274, -2.276],
                    [-0.178, -2.437],
                    [0, -2.5],
                    [0.178, -2.437],
                    [0.274, -2.276],
                    [0.403, -1.642],
                    [0.833, -0.839],
                    [1.642, -0.403],
                    [2.276, -0.274],
                    [2.437, -0.176],
                    [2.5, 0],
                    [2.437, 0.176]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 8,
      op: 1001,
      st: 1,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 4,
              s: [0]
            },
            { t: 42, s: [262] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.285 },
              o: { x: 0.92, y: 0 },
              t: 4,
              s: [150.5, 167.5, 0],
              to: [1.167, -13.333, 0],
              ti: [23.316, 56.005, 0]
            },
            { t: 31, s: [157.5, 87.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 27,
              s: [45, 45, 100]
            },
            { t: 32, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.114, -0.025],
                    [0, 0],
                    [0, 0],
                    [0.397, -0.397],
                    [0.108, -0.546],
                    [0, 0],
                    [0.09, -0.074],
                    [0.117, 0],
                    [0.09, 0.074],
                    [0.021, 0.113],
                    [0, 0],
                    [0.397, 0.397],
                    [0.553, 0.115],
                    [0, 0],
                    [0.073, 0.09],
                    [0, 0.115],
                    [-0.073, 0.09],
                    [-0.114, 0.025],
                    [0, 0],
                    [-0.397, 0.397],
                    [-0.108, 0.546],
                    [0, 0],
                    [-0.09, 0.074],
                    [-0.117, 0],
                    [-0.09, -0.074],
                    [-0.021, -0.113],
                    [0, 0],
                    [-0.397, -0.397],
                    [-0.553, -0.115],
                    [0, 0],
                    [-0.073, -0.09],
                    [0, -0.115],
                    [0.073, -0.09]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.553, 0.115],
                    [-0.397, 0.397],
                    [0, 0],
                    [-0.021, 0.113],
                    [-0.09, 0.074],
                    [-0.117, 0],
                    [-0.09, -0.074],
                    [0, 0],
                    [-0.108, -0.546],
                    [-0.397, -0.397],
                    [0, 0],
                    [-0.114, -0.025],
                    [-0.073, -0.09],
                    [0, -0.115],
                    [0.073, -0.09],
                    [0, 0],
                    [0.553, -0.115],
                    [0.397, -0.397],
                    [0, 0],
                    [0.021, -0.113],
                    [0.09, -0.074],
                    [0.117, 0],
                    [0.09, 0.074],
                    [0, 0],
                    [0.108, 0.546],
                    [0.397, 0.397],
                    [0, 0],
                    [0.114, 0.025],
                    [0.073, 0.09],
                    [0, 0.115],
                    [-0.073, 0.09]
                  ],
                  v: [
                    [4.096, 0.494],
                    [2.956, 0.725],
                    [2.956, 0.725],
                    [1.499, 1.51],
                    [0.725, 2.956],
                    [0.494, 4.096],
                    [0.321, 4.386],
                    [0, 4.5],
                    [-0.321, 4.386],
                    [-0.494, 4.096],
                    [-0.725, 2.956],
                    [-1.499, 1.51],
                    [-2.956, 0.725],
                    [-4.096, 0.494],
                    [-4.387, 0.317],
                    [-4.5, 0],
                    [-4.387, -0.317],
                    [-4.096, -0.494],
                    [-2.956, -0.725],
                    [-1.499, -1.51],
                    [-0.725, -2.956],
                    [-0.494, -4.096],
                    [-0.321, -4.386],
                    [0, -4.5],
                    [0.321, -4.386],
                    [0.494, -4.096],
                    [0.725, -2.956],
                    [1.499, -1.51],
                    [2.956, -0.725],
                    [4.096, -0.494],
                    [4.387, -0.317],
                    [4.5, 0],
                    [4.387, 0.317]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 8,
      op: 1001,
      st: 1,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 1,
              s: [0]
            },
            { t: 39, s: [262] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.403 },
              o: { x: 0.92, y: 0 },
              t: 1,
              s: [186.5, 167.5, 0],
              to: [1.333, -16.833, 0],
              ti: [-10.248, 21.242, 0]
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.195 },
              t: 28,
              s: [194.5, 66.5, 0],
              to: [9.211, -19.093, 0],
              ti: [-39.007, -96.126, 0]
            },
            { t: 41, s: [306.5, 92.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 35,
              s: [100, 100, 100]
            },
            { t: 42, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.114, -0.025],
                    [0, 0],
                    [0, 0],
                    [0.397, -0.397],
                    [0.108, -0.546],
                    [0, 0],
                    [0.09, -0.074],
                    [0.117, 0],
                    [0.09, 0.074],
                    [0.021, 0.113],
                    [0, 0],
                    [0.397, 0.397],
                    [0.553, 0.115],
                    [0, 0],
                    [0.073, 0.09],
                    [0, 0.115],
                    [-0.073, 0.09],
                    [-0.114, 0.025],
                    [0, 0],
                    [-0.397, 0.397],
                    [-0.108, 0.546],
                    [0, 0],
                    [-0.09, 0.074],
                    [-0.117, 0],
                    [-0.09, -0.074],
                    [-0.021, -0.113],
                    [0, 0],
                    [-0.397, -0.397],
                    [-0.553, -0.115],
                    [0, 0],
                    [-0.073, -0.09],
                    [0, -0.115],
                    [0.073, -0.09]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.553, 0.115],
                    [-0.397, 0.397],
                    [0, 0],
                    [-0.021, 0.113],
                    [-0.09, 0.074],
                    [-0.117, 0],
                    [-0.09, -0.074],
                    [0, 0],
                    [-0.108, -0.546],
                    [-0.397, -0.397],
                    [0, 0],
                    [-0.114, -0.025],
                    [-0.073, -0.09],
                    [0, -0.115],
                    [0.073, -0.09],
                    [0, 0],
                    [0.553, -0.115],
                    [0.397, -0.397],
                    [0, 0],
                    [0.021, -0.113],
                    [0.09, -0.074],
                    [0.117, 0],
                    [0.09, 0.074],
                    [0, 0],
                    [0.108, 0.546],
                    [0.397, 0.397],
                    [0, 0],
                    [0.114, 0.025],
                    [0.073, 0.09],
                    [0, 0.115],
                    [-0.073, 0.09]
                  ],
                  v: [
                    [4.096, 0.494],
                    [2.956, 0.725],
                    [2.956, 0.725],
                    [1.499, 1.51],
                    [0.725, 2.956],
                    [0.494, 4.096],
                    [0.321, 4.386],
                    [0, 4.5],
                    [-0.321, 4.386],
                    [-0.494, 4.096],
                    [-0.725, 2.956],
                    [-1.499, 1.51],
                    [-2.956, 0.725],
                    [-4.096, 0.494],
                    [-4.387, 0.317],
                    [-4.5, 0],
                    [-4.387, -0.317],
                    [-4.096, -0.494],
                    [-2.956, -0.725],
                    [-1.499, -1.51],
                    [-0.725, -2.956],
                    [-0.494, -4.096],
                    [-0.321, -4.386],
                    [0, -4.5],
                    [0.321, -4.386],
                    [0.494, -4.096],
                    [0.725, -2.956],
                    [1.499, -1.51],
                    [2.956, -0.725],
                    [4.096, -0.494],
                    [4.387, -0.317],
                    [4.5, 0],
                    [4.387, 0.317]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 8,
      op: 1001,
      st: 1,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.692, y: 1 },
              o: { x: 0.57, y: 0 },
              t: 0,
              s: [188.498, 222.504, 0],
              to: [0, -1.833, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.454, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 27,
              s: [188.498, 211.504, 0],
              to: [0, 0, 0],
              ti: [0, -1.833, 0]
            },
            { t: 52, s: [188.498, 222.504, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.407, y: 1 },
                    o: { x: 0.59, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0.004, -7.313],
                          [-18.468, -13.407],
                          [0.004, -19.501],
                          [18.476, -13.407]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.595, y: 0 },
                    t: 25,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0.004, -6.938],
                          [-18.468, -13.032],
                          [0.004, -19.126],
                          [18.476, -13.032]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [0.004, -7.313],
                          [-18.468, -13.407],
                          [0.004, -19.501],
                          [18.476, -13.407]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.59, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [2.504, -4.706],
                          [22.983, -11.462],
                          [27.999, -3.532],
                          [7.52, 3.224]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.59, y: 0 },
                    t: 25,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [2.504, -4.706],
                          [22.983, -11.462],
                          [27.999, -9.157],
                          [7.52, -2.401]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [2.504, -4.706],
                          [22.983, -11.462],
                          [27.999, -3.532],
                          [7.52, 3.224]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              hd: false
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.59, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-6.113, 7.139],
                          [-1.641, 0.069],
                          [-1.641, 19.501],
                          [-22.046, 12.769],
                          [-22.046, 1.882]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.59, y: 0 },
                    t: 25,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-6.113, 2.264],
                          [-1.641, 0.069],
                          [-1.641, 19.501],
                          [-22.046, 12.769],
                          [-22.046, -2.993]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-6.113, 7.139],
                          [-1.641, 0.069],
                          [-1.641, 19.501],
                          [-22.046, 12.769],
                          [-22.046, 1.882]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              hd: false
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.59, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [1.641, 0.069],
                          [6.113, 7.139],
                          [22.047, 1.882],
                          [22.047, 12.769],
                          [1.641, 19.501]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.59, y: 0 },
                    t: 25,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [1.641, 0.069],
                          [6.113, 1.514],
                          [22.047, -3.743],
                          [22.047, 12.769],
                          [1.641, 19.501]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [1.641, 0.069],
                          [6.113, 7.139],
                          [22.047, 1.882],
                          [22.047, 12.769],
                          [1.641, 19.501]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              hd: false
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.59, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-2.504, -4.706],
                          [-7.52, 3.224],
                          [-27.999, -3.532],
                          [-22.983, -11.462]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.41, y: 1 },
                    o: { x: 0.59, y: 0 },
                    t: 25,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-2.504, -4.706],
                          [-7.52, -1.651],
                          [-27.999, -8.407],
                          [-22.983, -11.462]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 51,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-2.504, -4.706],
                          [-7.52, 3.224],
                          [-27.999, -3.532],
                          [-22.983, -11.462]
                        ],
                        c: true
                      }
                    ]
                  }
                ],
                ix: 2
              },
              hd: false
            },
            {
              ty: 'mm',
              mm: 1,
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 7,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
